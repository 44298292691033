<template>
  <div>
    <v-form data-vv-scope="content">
      <v-layout row wrap>
        <v-flex xs6 class="mb-4">
          <div class="sw-h5">
            {{ $t("app_page_wizard.name") }}
          </div>
          <div class="mt-4 wrap-alignment-icon">
            <v-btn
              title="Left alignment"
              @click="setTitleConfig('left')"
              :class="{
                active:
                  groupArticle.article_title_alignment == 'left' ||
                  groupArticle.article_title_alignment == null,
              }"
              icon
            >
              <font-awesome-icon icon="align-left" />
            </v-btn>
            <v-btn
              title="Center alignment"
              @click="setTitleConfig('center')"
              :class="{
                active: groupArticle.article_title_alignment == 'center',
              }"
              icon
            >
              <font-awesome-icon icon="align-center" />
            </v-btn>
            <v-btn
              title="Right alignment"
              @click="setTitleConfig('right')"
              :class="{
                active: groupArticle.article_title_alignment == 'right',
              }"
              icon
            >
              <font-awesome-icon icon="align-right" />
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  title="Visibility"
                  @click="setTitleVisibility()"
                  :class="{
                    active:
                      groupArticle.article_title_visibility ||
                      groupArticle.article_title_visibility == null,
                  }"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small>
                    {{
                      groupArticle.article_title_visibility ||
                      groupArticle.article_title_visibility == null
                        ? "visibility"
                        : "visibility_off"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                $t("app_page_wizard.article_title_visibility_tooltip")
              }}</span>
            </v-tooltip>
          </div>
          <v-text-field
            v-model="groupArticle.name"
            v-validate.disable="'required'"
            data-vv-name="name"
            :data-vv-as="$t('app_page_wizard.name')"
            :placeholder="$t('app_page_wizard.name_placeholder')"
            hide-details
          ></v-text-field>
          <ErrorMessages :error-messages="errors.collect('content.name')" />
        </v-flex>
        <v-flex xs12>
          <div class="mb-4 sw-h5">
            {{ $t("app_page_wizard.body") }}
          </div>
          <QuillEditor
            v-model="groupArticle.body"
            :config="editorConfig"
            class="mb-3"
          />
          <ErrorMessages :error-messages="errors.collect('content.body')" />
        </v-flex>
        <v-flex xs12>
          <v-switch v-model="advancedSettings" class="my-0">
            <template slot="label">
              <div class="black--text">
                {{
                  advancedSettings
                    ? $t("common.hide_advanced_settings")
                    : $t("common.show_advanced_settings")
                }}
              </div>
            </template>
          </v-switch>
        </v-flex>
      </v-layout>
      <v-layout v-show="advancedSettings" row wrap class="pt-3">
        <v-flex xs12 class="mb-5">
          <v-layout class="row wrap">
            <v-flex xs12 class="mb-2">
              {{ $t("app_page_wizard.publish") }}
              <span class="sw-caption">
                {{ $t("app_page_wizard.publish_description") }}
              </span>
            </v-flex>
            <v-flex class="xs4 pr-5">
              <date-picker
                v-model="groupArticle.publish_at"
                @input="updatePublishUntil"
                width="99%"
                :lang="datePickerLang"
                type="datetime"
                :first-day-of-week="1"
                format="MMMM DD, YYYY H:mm"
                :minute-step="5"
                :placeholder="$t('app_page_wizard.publish_at')"
                :editable="false"
              ></date-picker>
              <span class="red--text">{{ errors.first("publish_at") }}</span>
            </v-flex>
            <v-flex class="xs4 pl-5">
              <date-picker
                v-model="groupArticle.publish_until"
                width="100%"
                :lang="datePickerLang"
                type="datetime"
                :first-day-of-week="1"
                format="MMMM DD, YYYY H:mm"
                :minute-step="5"
                :not-before="groupArticle.publish_at"
                :placeholder="$t('app_page_wizard.publish_until')"
                :editable="false"
              ></date-picker>
              <span class="red--text">{{ errors.first("publish_until") }}</span>
            </v-flex>
            <v-flex class="xs12 px-2 pt-3 grey--text sw-caption"
              >{{ $t("current-timezone") }}: {{ userTimezone }}</v-flex
            >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment-timezone";

export default {
  inject: ["parentValidator"],
  data: () => ({
    advancedSettings: false,
  }),
  computed: {
    groupArticle: {
      get() {
        return this.$store.getters.appPageWizardArticle;
      },
      set(v) {
        this.$store.dispatch("setAppPageWizardArticle", v);
      },
    },
    editorConfig() {
      return {
        label: "",
        placeholder: this.$t("app_page_wizard.body_placeholder"),
        iframes: true,
        style: {
          height: "600px",
        },
      };
    },
    advancedSettingsLabel() {
      return this.advancedSettings
        ? this.$t("app_page_wizard.advanced_settings_true")
        : this.$t("app_page_wizard.advanced_settings_false");
    },
  },
  components: {
    DatePicker,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    updatePublishUntil() {
      if (!this.groupArticle.publish_until) return;

      const minPublishUntil = moment(this.groupArticle.publish_at)
        .add(24, "hours")
        .toISOString();

      const untilIsSameOrBeforeMinUntil = moment(
        this.groupArticle.publish_until,
      ).isSameOrBefore(minPublishUntil);

      if (
        untilIsSameOrBeforeMinUntil ||
        this.groupArticle.publish_until === null
      ) {
        this.groupArticle.publish_until = minPublishUntil;
      }
    },
    setTitleConfig(value) {
      this.groupArticle = {
        ...this.groupArticle,
        article_title_alignment: value,
      };
    },
    setTitleVisibility() {
      this.groupArticle = {
        ...this.groupArticle,
        article_title_visibility: !Boolean(
          this.groupArticle.article_title_visibility,
        ),
      };
    },
  },
};
</script>
<style scoped lang="scss">
button {
  margin: 0;
}
button.active {
  div {
    i,
    svg {
      color: $accent !important;
    }
  }
}
.wrap-alignment-icon {
  margin-left: -10px;
}
</style>
